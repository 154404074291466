import { Song } from './types';

export const mergedSong = (appleSong?: Song, spotifySong?: Song) =>
  appleSong === undefined && spotifySong === undefined
    ? undefined
    : ({
        ...appleSong,
        ...spotifySong,
      } as Song);

// AB1234567890
export const normalizedISRC = (isrc: string) => isrc.replace(/-/g, '');

// AB-123-45-67890
export const standardISRC = (isrc: string) =>
  [
    isrc.substr(0, 2),
    isrc.substr(2, 3),
    isrc.substr(5, 2),
    isrc.substr(7, 5),
  ].join('-');

export const isSameSong = (a: Song | undefined, b: Song) =>
  a?.isrc === b.isrc ||
  (a?.spotify === b.spotify && b.spotify !== undefined) ||
  (a?.apple === b.apple && b.apple !== undefined);
