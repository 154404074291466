import querystring from 'querystring';
import { v4 as uuidv4 } from 'uuid';
import { authorizeSpotifyUser } from '../../api';

const clientId = '8226d34084c0402b844ceda772756253';

const redirectURI =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/spotify'
    : 'https://radio.andrei.codes/spotify';

const scopes = [
  'user-modify-playback-state',
  'user-read-playback-state',
  'user-read-currently-playing',
  'user-follow-read',
  'streaming',
  'user-read-email',
  'user-read-private',
];

function secret() {
  let secret = localStorage.getItem('spotify_secret');
  if (secret === null) {
    secret = uuidv4();
    localStorage.setItem('spotify_secret', secret);
  }

  return secret;
}

export async function authorize(code: string, state: string) {
  if (state !== secret()) {
    throw new Error('Incorrect state');
  }

  const sessionToken = await authorizeSpotifyUser(code, redirectURI);

  localStorage.removeItem('spotify_secret');

  return sessionToken;
}

export function getConnectURL() {
  return (
    'https://accounts.spotify.com/authorize?' +
    querystring.stringify({
      response_type: 'code',
      client_id: clientId,
      scope: scopes.sort().join(' '),
      redirect_uri: redirectURI,
      state: secret(),
    })
  );
}
