import { playTracks, stopPlaying } from './api';
import { spotifyAccessToken } from '../../api';
import { getSessionToken } from '../../auth';
import { PlaybackState } from '../../common/types';

declare global {
  interface Window {
    onSpotifyWebPlaybackSDKReady: any;
    Spotify: any;
  }
}

type PlaybackStateChangedCallback = (state: PlaybackState, id: string) => void;
type ReadyChangedCallback = (ready: boolean) => void;

const noop = () => {};

let webPlaybackSDKReady = false;

window.onSpotifyWebPlaybackSDKReady = () => {
  webPlaybackSDKReady = true;
};

class SpotifyPlayer {
  playbackStateChangedCallback: PlaybackStateChangedCallback = noop;
  readyCallback: ReadyChangedCallback = noop;

  device: string | undefined;
  accessToken: string | undefined;

  async initialize() {
    console.log('SpotifyPlayer.initialize');

    const player = await this.initWebPlayer();

    player.addListener('initialization_error', (message: any) => {
      console.error('initialization_error', message);
    });

    player.addListener('authentication_error', (message: any) => {
      console.error('authentication_error', message);
    });

    player.addListener('account_error', (message: any) => {
      console.error('account_error', message);
    });

    player.addListener('playback_error', (message: any) => {
      console.error('playback_error', message);
    });

    player.addListener('player_state_changed', (state: any) => {
      // console.log('player_state_changed', state);
      this.playbackStateChangedCallback(
        state.paused ? 'paused' : 'playing',
        state.track_window.current_track.id
      );
    });

    player.addListener('ready', (e: any) => {
      this.device = e.device_id;
      this.readyCallback(true);
    });

    player.addListener('not_ready', (e: any) => {
      console.log('not ready');
      this.device = undefined;
      this.readyCallback(false);
    });

    await player.connect();
  }

  onPlaybackStateChanged(callback: PlaybackStateChangedCallback) {
    this.playbackStateChangedCallback = callback;
  }

  onReady(callback: ReadyChangedCallback) {
    this.readyCallback = callback;
  }

  async initWebPlayer() {
    const newWebPlayer = () =>
      new window.Spotify.Player({
        name: 'radio',
        getOAuthToken: (callback: any) => {
          spotifyAccessToken(getSessionToken()).then((accessToken) => {
            this.accessToken = accessToken;
            callback(this.accessToken);
          });
        },
      });

    if (webPlaybackSDKReady) {
      return newWebPlayer();
    } else {
      return new Promise((resolve) => {
        window.onSpotifyWebPlaybackSDKReady = () => {
          webPlaybackSDKReady = true;
          resolve(newWebPlayer());
        };
      });
    }
  }

  async playTracks(ids: string[]) {
    if (!this.accessToken || !this.device) {
      return;
    }

    await playTracks(ids, this.device, this.accessToken);
  }

  async stopPlaying() {
    if (!this.accessToken || !this.device) {
      return;
    }

    await stopPlaying(this.device, this.accessToken);
  }
}

export default SpotifyPlayer;
