export function setSessionToken(token?: string) {
  if (token) {
    localStorage.setItem('session_token', token);
  } else {
    localStorage.removeItem('session_token');
  }
}

export function getSessionToken() {
  return localStorage.getItem('session_token') || undefined;
}
