import { getAppleSignInState } from '../../api';

const redirectURI =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/apple'
    : 'https://radio.andrei.codes/apple';

export function isUserAuthorized(): boolean {
  return true;
}

export async function authorizeUser(sessionToken?: string) {
  window.AppleID.auth.init({
    clientId: 'com.idevelop.radio.web',
    scope: 'name email',
    redirectURI: `https://europe-west3-radio-c1928.cloudfunctions.net/authorizeAppleUser`,
    state: await getAppleSignInState(sessionToken, redirectURI),
  });

  window.AppleID.auth.signIn();
}
