import React from 'react';
import { TimelinePost, Song } from '../../../common/types';
import { Card } from 'react-bootstrap';
import { isSameSong } from '../common/util';

type Props = {
  posts: TimelinePost[];
  currentSong?: Song;
  playbackState?: string;
};

export default ({ posts, playbackState, currentSong }: Props) => (
  <div id="timeline">
    {posts
      .filter((post) => !!post.song)
      .map((post) => (
        <Card
          key={post.time}
          bg={
            isSameSong(currentSong, post.song) && playbackState === 'playing'
              ? 'primary'
              : undefined
          }
          text={
            isSameSong(currentSong, post.song) && playbackState === 'playing'
              ? 'white'
              : undefined
          }
          border={
            isSameSong(currentSong, post.song) && playbackState !== 'playing'
              ? 'primary'
              : undefined
          }
          className={`card rounded-lg shadow-sm p-0 mt-3 container-sm d-flex`}
          style={{
            flexDirection: 'row',
            overflow: 'hidden',
          }}
        >
          <img src={post.song.artwork} width="100" height="100" alt="cover" />
          <div className="card-body">
            <h5 className="card-title">{post.song.name}</h5>
            <p className="card-text">{post.song.artist}</p>
            {/* <p>
              Apple: {post.song.apple}, Spotify: {post.song.spotify}
            </p> */}
          </div>
        </Card>
      ))}
  </div>
);
