import React, { useEffect, useState, useCallback } from 'react';
import { ListGroup } from 'react-bootstrap';

import { Song } from '../../../common/types';
import * as api from '../api';

let lastSearchTime = 0;

const isUrl = (text: string) =>
  text.startsWith('http://') || text.startsWith('https://');

async function fetchSearchResults(q: string, time: number) {
  const results = await api.search(q);
  return { results, time };
}

async function fetchPreview(url: string) {
  return await api.preview(url);
}

async function updateSearchResults(q: string) {
  lastSearchTime = new Date().getTime();

  if (isUrl(q)) {
    const songPreview = await fetchPreview(q);
    return [songPreview];
  } else if (q.length > 1) {
    const { results, time } = await fetchSearchResults(q, lastSearchTime);
    if (time === lastSearchTime) {
      return results;
    } else {
      throw new Error('Stale results');
    }
  } else {
    return [];
  }
}

type Props = {
  query: string;
  onSearchStateUpdated: (searching: boolean) => void;
  onSearchResultSelected: (song: Song) => void;
};

export default ({
  query,
  onSearchStateUpdated,
  onSearchResultSelected,
}: Props) => {
  const [searchResults, setSearchResults] = useState<Song[]>([]);

  const search = useCallback(
    async (q: string) => {
      try {
        onSearchStateUpdated(true);
        setSearchResults(await updateSearchResults(q));
        onSearchStateUpdated(false);
      } catch (e) {
        // stale results, discarded
      }
    },
    [onSearchStateUpdated]
  );

  useEffect(() => {
    search(query);
  }, [query, search]);

  return (
    <>
      {searchResults.length > 0 && (
        <ListGroup variant="flush">
          {searchResults.map((song) => (
            <ListGroup.Item
              action
              key={song.isrc}
              className="px-3 py-2"
              onClick={() => {
                onSearchResultSelected(song);
              }}
            >
              {song.artist} - {song.name} ({song.album})
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </>
  );
};
