import React from 'react';
import { render } from 'react-dom';
import { Router } from '@reach/router';

import Home from './pages/Home';
import SpotifyConnect from './pages/SpotifyConnect';
import AppleConnect from './pages/AppleConnect';

import './css/style.css';

render(
  <Router>
    <Home path="/" />
    <AppleConnect path="/apple" />
    <SpotifyConnect path="/spotify" />
  </Router>,
  document.getElementById('root')
);
