import querystring from 'querystring';
import { TimelinePost, Song, Hello } from './common/types';

const domains = {
  development: 'https://europe-west3-radio-staging.cloudfunctions.net',
  test: 'https://europe-west3-radio-staging.cloudfunctions.net',
  staging: 'https://europe-west3-radio-staging.cloudfunctions.net',
  production: 'https://europe-west3-radio-c1928.cloudfunctions.net',
};

export const endpoint = (
  name: string,
  query = {},
  environment = process.env.NODE_ENV
) => `${domains[environment]}/${name}?${querystring.stringify(query)}`;

export async function preview(url: string): Promise<Song> {
  const response = await fetch(endpoint('preview', { url }, 'production'));
  return await response.json();
}

export async function postURL(url: string) {
  const response = await fetch(endpoint('postURL', { url }, 'production'));
  const text = await response.text();

  console.log(text);
}

export async function postISRC(isrc: string) {
  const response = await fetch(
    endpoint('postISRC', { isrc, country: 'gb' }, 'production')
  );

  const text = await response.text();

  console.log(text);
}

export async function search(q: string): Promise<Song[]> {
  const response = await fetch(
    endpoint('search', { q, country: 'gb' }, 'production')
  );

  return await response.json();
}

export async function timeline(): Promise<TimelinePost[]> {
  const response = await fetch(endpoint('timeline', {}, 'production'));

  return await response.json();
}

export async function getAppleSignInState(
  sessionToken: string | undefined,
  redirectURI: string
) {
  const response = await fetch(
    endpoint('appleSignInState', { sessionToken, redirectURI }, 'production')
  );

  return await response.text();
}

export async function authorizeSpotifyUser(
  code: string,
  redirectURI: string
): Promise<string> {
  const response = await fetch(
    endpoint('authorizeSpotifyUser', { code, redirectURI }, 'production')
  );

  return await response.text();
}

export async function hello(sessionToken: string | undefined): Promise<Hello> {
  const response = await fetch(
    endpoint('hello', { sessionToken }, 'production')
  );

  return await response.json();
}

export async function spotifyAccessToken(
  sessionToken: string | undefined
): Promise<string> {
  const response = await fetch(
    endpoint('spotifyAccessToken', { sessionToken }, 'production')
  );

  return await response.text();
}

export async function saveAppleMusicToken(
  sessionToken: string | undefined,
  appleMusicToken: string
): Promise<string> {
  const response = await fetch(
    endpoint(
      'saveAppleMusicToken',
      { sessionToken, appleMusicToken },
      'production'
    )
  );

  return await response.text();
}
