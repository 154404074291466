import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import {
  PlaybackStateChangedCallback,
  PlaybackState,
  Song,
} from '../../../common/types';
import ApplePlayer from '../providers/apple/player';

type Props = {
  songs: Song[];
  onPlaybackStateChanged: PlaybackStateChangedCallback;
};

export default ({ songs, onPlaybackStateChanged }: Props) => {
  const [initialized, setInitialized] = useState(false);
  const [playing, setPlaying] = useState(false);

  const playerRef = useRef(new ApplePlayer());

  const findSongFromSongId = useCallback(
    (id: string) => songs.find((song) => song.apple === id),
    [songs]
  );

  async function init() {
    const player = playerRef.current;

    await player.initialize();

    player.onPlaybackStateChanged((state: PlaybackState, id: string) => {
      console.log('onPlaybackStateChanged', state, id);
      setPlaying(state === 'playing');
      onPlaybackStateChanged(state, findSongFromSongId(id));
    });

    setInitialized(true);
  }

  useEffect(() => {
    init();
  }, []);

  const playRandomSong = useCallback(() => {
    const randomSong = songs[Math.floor(Math.random() * songs.length)];
    if (randomSong !== undefined) {
      console.log('playing', randomSong);
      playerRef.current.playSong(randomSong.isrc);
    }
  }, [songs]);

  return initialized ? (
    playing ? (
      <Button
        className="mx-2"
        variant="danger"
        onClick={() => playerRef.current.stopPlaying()}
      >
        Stop
      </Button>
    ) : (
      <Button className="mx-2" variant="success" onClick={playRandomSong}>
        Play with Apple Music
      </Button>
    )
  ) : (
    <Button className="mx-2" variant="light" disabled={true}>
      Initializing
    </Button>
  );
};
