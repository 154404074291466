import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import {
  Song,
  PlaybackStateChangedCallback,
  PlaybackState,
} from '../common/types';
import SpotifyPlayer from '../providers/spotify/player';

type Props = {
  songs: Song[];
  onPlaybackStateChanged: PlaybackStateChangedCallback;
};

export default ({ songs, onPlaybackStateChanged }: Props) => {
  const [initialized, setInitialized] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [ready, setReady] = useState(false);

  const playerRef = useRef<SpotifyPlayer>(new SpotifyPlayer());

  const findSongFromSongId = useCallback(
    (id: string) => songs.find((song) => song.spotify === id),
    [songs]
  );

  async function init() {
    const player = playerRef.current;

    await player.initialize();

    player.onPlaybackStateChanged((state: PlaybackState, id: string) => {
      setPlaying(state === 'playing');
      onPlaybackStateChanged(state, findSongFromSongId(id));
    });

    player.onReady(setReady);

    setInitialized(true);
  }

  useEffect(() => {
    init();
  }, []);

  const playSongs = useCallback(async () => {
    await playerRef.current.playTracks(
      songs
        .filter((song) => !!song.spotify)
        .map((song) => song.spotify as string)
    );
  }, [songs]);

  return initialized ? (
    playing ? (
      <Button
        className="mx-2"
        variant="danger"
        onClick={() => playerRef.current.stopPlaying()}
      >
        Stop
      </Button>
    ) : (
      <Button
        className="mx-2"
        variant="success"
        disabled={!ready}
        onClick={() => playSongs()}
      >
        Play with Spotify
      </Button>
    )
  ) : (
    <Button className="mx-2" variant="light" disabled={true}>
      Initializing
    </Button>
  );
};
