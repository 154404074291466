import { PlaybackState } from '../../common/types';

declare global {
  interface Window {
    MusicKit: any;
    AppleID: any;
  }
}

type PlaybackStateChangedCallback = (state: PlaybackState, id: string) => void;

const noop = () => {};

class ApplePlayer {
  playbackStateChangedCallback: PlaybackStateChangedCallback = noop;

  initialize() {
    const instance = window.MusicKit.getInstance();

    // user token = instance.storekit.userToken
    // https://developer.apple.com/documentation/applemusicapi/getting_keys_and_creating_tokens
    // renew: POST https://play.itunes.apple.com/WebObjects/MZPlay.woa/wa/renewMusicToken
    // authorization: Bearer {developerToken}
    // x-apple-music-user-token: {accessToken}

    instance.player.addEventListener('playbackStateDidChange', (event: any) => {
      // console.log(
      //   'playbackStateDidChange',
      //   `${window.MusicKit.PlaybackStates[event.oldState]} -> ${
      //     window.MusicKit.PlaybackStates[event.state]
      //   }`
      // );

      const state = window.MusicKit.PlaybackStates[
        event.state
      ] as PlaybackState;

      const isrc =
        state === 'stopped'
          ? undefined
          : instance.player.nowPlayingItem
          ? instance.player.nowPlayingItem.attributes.isrc.substr(-12)
          : undefined;

      if (this.playbackStateChangedCallback) {
        this.playbackStateChangedCallback(state, isrc);
      }
    });

    // instance.player.addEventListener('mediaItemDidChange', (event: any) => {
    //   console.log('mediaItemDidChange', event);
    // });

    // instance.player.addEventListener(
    //   'queuePositionDidChange',
    //   (a: any, b: any, c: any) => {
    //     console.log('queuePositionDidChange', a, b, c);
    //   }
    // );
  }

  onPlaybackStateChanged(callback: PlaybackStateChangedCallback) {
    this.playbackStateChangedCallback = callback;
  }

  async playSong(isrc: string) {
    const instance = window.MusicKit.getInstance();

    // console.log(queue, queue.length);

    // const items = timeline.map((post) => ({ song: post.song.apple }));
    // console.log('playAppleMusic', queue);

    // await instance.player.queue.append({
    //   song: timeline[0].song.apple,
    // });

    const songs = await instance.api.songs([], {
      filter: {
        isrc,
      },
    });

    await instance.setQueue({ song: songs[0].id });
    await instance.player.play();
  }

  async stopPlaying() {
    const instance = window.MusicKit.getInstance();
    await instance.player.stop();
  }
}

export default ApplePlayer;
