export function configure(developerToken: string) {
  window.MusicKit.configure({
    developerToken,
    app: {
      name: 'radio',
      build: '1',
    },
  });
}

export function isMusicAuthorized(): boolean {
  try {
    return window.MusicKit.getInstance().isAuthorized;
  } catch (e) {
    return false;
  }
}

export async function authorizeMusic() {
  try {
    await window.MusicKit.getInstance().authorize();
  } catch (e) {
    console.error(e);
  }
}

export function getAppleMusicToken() {
  return window.MusicKit.getInstance().musicUserToken;
}

export function playerInstance() {
  return window.MusicKit.getInstance();
}
