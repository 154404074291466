import React from 'react';

type Props = {
  size?: number;
  animated?: boolean;
};

const defaults: Props = {
  size: 128,
  animated: false,
};

export default ({ size, animated }: Props) => (
  <img
    id="logo"
    alt="sup"
    src="cassette-2.png"
    width={size || defaults.size}
    className="mx-auto d-block"
    style={{
      ...(animated && {
        position: 'absolute',
        top: '50%',
        left: '50%',
        animation: 'wiggle 2s',
        animationIterationCount: 'infinite',
      }),
    }}
  />
);
