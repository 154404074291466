import React, { useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import SearchResults from './SearchResults';
import * as api from '../api';
import { Song } from '../../../common/types';

type Props = {
  onSongPosted: (song: Song) => void;
};

export default ({ onSongPosted }: Props) => {
  const [text, setText] = useState('');
  const [searching, setSearching] = useState(false);

  async function postURLToTimeline(url: string) {
    return await api.postURL(url);
  }

  async function postISRCToTimeline(isrc: string) {
    return await api.postISRC(isrc);
  }

  return (
    <div className="card rounded-lg border-dark shadow-sm p-0 mt-3 container-sm">
      <Form
        onSubmit={(e: any) => {
          e.preventDefault();
          setText('');
          postURLToTimeline(text);
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Form.Control
          type="text"
          value={text}
          className="px-3 py-3"
          style={{ border: 'none', outline: 'none' }}
          placeholder="Search for a song or paste a URL from Spotify or Apple Music"
          onChange={(e: any) => setText(e.target.value)}
        />

        {searching && (
          <Spinner
            animation="grow"
            variant="primary"
            size="sm"
            className="mr-2"
            style={{
              marginLeft: '-2rem',
            }}
          />
        )}
      </Form>

      <SearchResults
        query={text}
        onSearchStateUpdated={setSearching}
        onSearchResultSelected={(song) => {
          setText('');
          onSongPosted(song);
          postISRCToTimeline(song.isrc);
        }}
      />
    </div>
  );
};
