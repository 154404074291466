import React, { useEffect } from 'react';
import { LocationContext } from '@reach/router';
import { parse } from 'querystring';
import { setSessionToken } from '../auth';
import Logo from '../components/Logo';

export default ({ location, navigate }: LocationContext) => {
  useEffect(() => {
    const { sessionToken } = parse(location.search.substr(1));
    setSessionToken(sessionToken as string);
    navigate('/');
  }, [location, navigate]);

  return <Logo size={256} animated={true} />;
};
