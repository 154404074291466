import React, { useEffect, useState } from 'react';
import { Spinner, Button } from 'react-bootstrap';

import {
  configure,
  isMusicAuthorized,
  authorizeMusic,
  getAppleMusicToken,
} from '../providers/apple/musicKit';

import Composer from '../components/Composer';
import ApplePlayer from '../components/ApplePlayer';
import SpotifyPlayer from '../components/SpotifyPlayer';
import Timeline from '../components/Timeline';
import * as api from '../api';
import { TimelinePost, Song } from '../common/types';
import { getSessionToken, setSessionToken } from '../auth';
import { authorizeUser } from '../providers/apple/signIn';
import { getConnectURL } from '../providers/spotify/auth';
import Logo from '../components/Logo';

const now = () => Math.round(new Date().getTime() / 1000);

export default () => {
  const [timeline, setTimeline] = useState<TimelinePost[]>([]);
  const [loading, setLoading] = useState(true);
  const [playbackState, setPlaybackState] = useState<string>();
  const [currentSong, setCurrentSong] = useState<Song | undefined>();
  const [appleSignedIn, setAppleSignedIn] = useState(false);
  const [spotifySignedIn, setSpotifySignedIn] = useState(false);
  const [appleMusicAuthorized, setAppleMusicAuthorized] = useState(false);

  async function init() {
    const {
      sessionToken,
      user,
      timeline,
      appleDeveloperToken,
      isAppleSignedIn,
      isSpotifySignedIn,
    } = await api.hello(getSessionToken());

    console.log(user);

    // Configure MusicKit
    configure(appleDeveloperToken);
    setAppleMusicAuthorized(isMusicAuthorized());

    setAppleSignedIn(isAppleSignedIn);
    setSpotifySignedIn(isSpotifySignedIn);
    setSessionToken(sessionToken);
    setTimeline(timeline);

    setLoading(false);
  }

  useEffect(() => {
    init();
  }, []);

  const authorizeAppleMusic = async () => {
    await authorizeMusic();
    const authorized = isMusicAuthorized();
    setAppleMusicAuthorized(authorized);
    if (authorized) {
      const sessionToken = await api.saveAppleMusicToken(
        getSessionToken(),
        getAppleMusicToken()
      );

      setSessionToken(sessionToken);
    }
  };

  return (
    <>
      <Logo size={256} />

      {loading ? (
        <Spinner
          animation="grow"
          variant="primary"
          className="mx-auto my-4 d-block"
        />
      ) : (
        <>
          <div
            className="d-flex mt-3"
            style={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {!appleSignedIn && (
              <Button
                className="mx-2"
                variant="primary"
                onClick={() => authorizeUser(getSessionToken())}
              >
                Sign in with Apple
              </Button>
            )}

            {appleMusicAuthorized ? (
              <ApplePlayer
                songs={timeline.map((post) => post.song)}
                onPlaybackStateChanged={(state, song) => {
                  console.log('Apple.onPlaybackStateChanged', state, song);
                  setCurrentSong(song);
                  setPlaybackState(state);
                }}
              />
            ) : (
              <Button
                className="mx-2"
                variant="primary"
                onClick={authorizeAppleMusic}
              >
                Authorize Apple Music
              </Button>
            )}

            {spotifySignedIn ? (
              <SpotifyPlayer
                songs={timeline.map((post) => post.song)}
                onPlaybackStateChanged={(state, song) => {
                  console.log('Spotify.onPlaybackStateChanged', state, song);
                  setCurrentSong(song);
                  setPlaybackState(state);
                }}
              />
            ) : (
              <Button className="mx-2" variant="primary" href={getConnectURL()}>
                Connect with Spotify
              </Button>
            )}
          </div>

          {(spotifySignedIn || appleSignedIn) && (
            <Composer
              onSongPosted={(song) => {
                setTimeline([{ time: now(), song }, ...timeline]);
              }}
            />
          )}

          <Timeline
            posts={timeline}
            currentSong={currentSong}
            playbackState={playbackState}
          />
        </>
      )}
    </>
  );
};
