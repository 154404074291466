import React, { useEffect, useCallback } from 'react';
import { LocationContext } from '@reach/router';
import { parse } from 'querystring';
import { authorize } from '../providers/spotify/auth';
import { setSessionToken } from '../auth';
import Logo from '../components/Logo';

export default ({ location, navigate }: LocationContext) => {
  const authorizeUser = useCallback(
    async (code: string, state: string) => {
      try {
        const sessionToken = await authorize(code, state);
        setSessionToken(sessionToken);
        navigate('/');
      } catch (e) {
        console.error('something went wrong', e);
        navigate('/');
      }
    },
    [navigate]
  );

  useEffect(() => {
    const params = parse(location.search.substr(1));
    // TODO: handle errors passed in query params
    // https://developer.spotify.com/documentation/general/guides/authorization-guide/#scopes

    authorizeUser(params.code as string, params.state as string);
  }, [authorizeUser, location]);

  return <Logo size={256} animated={true} />;
};
