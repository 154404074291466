export type Device = {
  id: string;
  is_active: boolean;
  is_private_session: boolean;
  is_restricted: boolean;
  name: string;
  type: string;
  volume_percent: number;
};

export async function getDevices(accessToken: string): Promise<Device[]> {
  const response = await fetch('https://api.spotify.com/v1/me/player/devices', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const json = await response.json();

  return json.devices;
}

export async function playTracks(
  ids: string[],
  device: string,
  accessToken: string
) {
  await fetch('https://api.spotify.com/v1/me/player/play?device_id=' + device, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      uris: ids.map((id) => `spotify:track:${id}`),
    }),
  });
}

export async function stopPlaying(device: string, accessToken: string) {
  await fetch(
    'https://api.spotify.com/v1/me/player/pause?device_id=' + device,
    {
      method: 'put',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}
